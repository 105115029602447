import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: '',
    redirectTo: 'dashboard/home',
    pathMatch: 'full',
  },
  
  {
    path: 'dashboard',
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then(
            ({ HomeModule }) => HomeModule
          ),
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./modules/help/help.module').then(
            ({ HelpModule }) => HelpModule
          ),
          outlet: 'popups'
      },
      {
        path: 'passengers',
        loadChildren: () =>
          import('./modules/passengers/passengers.module').then(
            ({ PassengersModule }) => PassengersModule
          ),
      },
      {
        path: 'employees',
        loadChildren: () =>
          import('./modules/employees/employee.module').then(
            ({ EmployeeModule }) => EmployeeModule
          ),
      },
      {
        path: 'addresses',
        loadChildren: () =>
          import('./modules/addresses/addresses.module').then(
            ({ AddressesModule }) => AddressesModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            ({ ProfileModule }) => ProfileModule
          ),
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./modules/subscriptions/subscriptions.module').then(
            ({ SubscriptionsModule }) => SubscriptionsModule
          ),
      },
      {
        path: 'payment-methods',
        loadChildren: () =>
          import('./modules/payment-methods/payment-methods.module').then(
            ({ PaymentMethodsModule }) => PaymentMethodsModule
          ),
      },
      {
        path: 'wallet-refunds',
        loadChildren: () =>
          import('./modules/wallet-refunds/wallet-refunds.module').then(
            ({ WalletRefundsModule }) => WalletRefundsModule
          ),
      },
      {
        path: 'transaction-receipts',
        loadChildren: () =>
          import(
            './modules/transactions-receipts/transactions-receipts.module'
          ).then(
            ({ TransactionsReceiptsModule }) => TransactionsReceiptsModule
          ),
      },
      {
        path: 'contracts',
        loadChildren: () =>
          import('./modules/contracts/contracts.module').then(
            ({ ContractsModule }) => ContractsModule
          ),
      },
      {
        path: 'monitoring',
        loadChildren: () =>
          import('./modules/monitoring/monitoring.module').then(
            ({ MonitoringModule }) => MonitoringModule
          ),
      },
      {
        path: 'monitoring/journeys',
        loadChildren: () =>
          import('./modules/monitor-journeys/monitor-journeys.module').then(
            ({ MonitorJourneysModule }) => MonitorJourneysModule
          ),
      },
      
      {
        path: 'lost-items-requests',
        loadChildren: () =>
          import('./modules/lost-items-requests/lost-items-requests.module').then(
            ({ LostItemsRequestsModule }) => LostItemsRequestsModule
          ),
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
