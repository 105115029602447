import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { appInitialActions } from 'src/app/core/root-store/root.actions';
import { environment } from 'src/environments/environment';
import { selectAppConnectionStatus } from 'src/app/core/root-store/root.selectors';
import { AppStateInterface } from 'src/app/core/root-store/root.state';
import {NgxHttpLoaderService, NgxLoader} from 'ngx-http-loader';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LocalStorageService } from './kernel/tools/services/local-storage.service';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from './vogo-core/services/language.service';
import { AuthService } from './vogo-core/services/auth.service';
import { filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'The Coolest App On Earth';
  loader = NgxLoader;
  lang = 'en';
  constructor(
    private store: Store<AppStateInterface>,
    private router: Router,
    private ngxhttploader: NgxHttpLoaderService,
    private localStorage : LocalStorageService,
    @Inject(DOCUMENT) private document: Document,
    private langService: LanguageService,
    private authService: AuthService,
  ) {
    this.lang = this.localStorage.get('lang') ?? 'en';

    if (this.lang === 'ar')
      this.document.body.classList.add('rtl-theme');
  }

  ngOnInit() {
    this.store.dispatch(
      appInitialActions.appStarted({ version: environment.version }),
    );
    this.store.select(selectAppConnectionStatus).subscribe((state) => {
      if (state > 0) {
        console.log('%c✅ Online', 'color:green;font-size: 18px');
      } else if (state === 0) {
        console.log('%c🚫 Offline', 'color:red;font-size: 18px');
      } else {
        console.log('Testing connection...');
      }
    });
    this.checkRouteState();
    this.observeLanguageChange();
    this.observeUserChange();
  }

  private checkRouteState() {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.ngxhttploader.show();
        }
        if (event instanceof NavigationEnd) {
          this.ngxhttploader.hide();
        }

        // Set loadingRoute state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
          this.ngxhttploader.hide();
        }
        if (event instanceof NavigationError) {
          this.ngxhttploader.hide();
        }
      },
    });
  }

  private observeLanguageChange() {
    this.langService.currentLang$
    .pipe(
    ).subscribe(_ => {
      location.reload();
    })
  }

  /**
   * Will check if user$ change that means any auth action might be happened
   */
  private observeUserChange() {
    this.authService.user$
    .pipe(
      switchMap(_ => this.router.events),
      filter(data => data instanceof NavigationEnd)
    )
    .subscribe(_ => {
      const userDetails = this.authService.getUserDetails();
      if(userDetails?.preferredLang) {
        this.langService.changeLang(userDetails.preferredLang);
      }
    })
  }
}
