<ngx-http-loader
  [customTemplate]="loaderTemplate"
  [filterHttpHeaders]="['ignore_http_loader']"
>
  <ng-template #loaderTemplate>
    <app-progress-bar></app-progress-bar>
  </ng-template>
</ngx-http-loader>

<!--Page Styles-->
<div class="page">
  <router-outlet></router-outlet>
</div>

<p-toast position="top-right"></p-toast>

<sxw-popup-wrapper></sxw-popup-wrapper>